<template>
  <section>
    <v-parallax height="380"
                :src="require('@/assets/daniel-chen-546446-unsplash-sm.webp')">
      <v-layout align-center column justify-center>

        <!-- Headline -->
        <div class="headline mb-3 text-xs-center white--text font-l">
          {{ texts.headline[textLang] }}
        </div>

        <!-- Text -->
        <div class="mb-3 text-xs-center title font-s">
          {{ texts.text[textLang] }}
        </div>

        <!-- Contact Button -->
        <ContactDialog  class="font-xs" :text="texts.joinUs[textLang]" :textLang="textLang" :key="textLang"></ContactDialog>
      </v-layout>
    </v-parallax>
  </section>
</template>

<script>
// Project-specific imports
import ContactDialog from './ContactDialog.vue';

export default {
  components: {
    ContactDialog
  },
  data () {
    const texts = {
      headline: {
        cs: 'Hledáme kolegy do týmu!',
        en: 'We are hiring!'
      },
      text: {
        cs: 'Neustále hledáme vývojáře pro posílení našeho týmu. Zajímá Tě'
          + ' umělá inteligence, strojové učení, nebo data science?',
        en: 'Looking for software developers to strengthen our team. Are you'
          + ' interested in AI, machine learning, or data science?'
      },
      joinUs: {
        cs: 'Přidej se k Nám',
        en: 'Join Us'
      }
    };

    return {
      texts: texts
    };
  },
  props: ['textLang']
};
</script>