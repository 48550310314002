<template>
  <v-footer class="pa-5">
    <v-layout justify-center>
      <div class="font-xxs" v-html="text[textLang]"></div>
    </v-layout>
  </v-footer>
</template>

<script>
export default {
  data () {
    let date = new Date().getFullYear();
    let text = {
      cs: '&copy; ' + date + ' semeai s.r.o.'
      + ' &ensp;|&ensp;'
      + ' U&nbsp;Náhonu&nbsp;128, 760&nbsp;01&nbsp;Zlín, Česká&nbsp;republika'
      + ' &ensp;|&ensp;'
      + ' IČ:&nbsp;07803575'
      + ' &ensp;|&ensp;'
      + ' DIČ:&nbsp;CZ07803575'
      + ' &ensp;|&ensp;'
      + ' C&nbsp;110204 vedená u Krajského soudu v Brně',
      en: '&copy; ' + date + ' semeai s.r.o.'
      + ' &ensp;|&ensp;'
      + ' U&nbsp;Náhonu&nbsp;128, 760&nbsp;01&nbsp;Zlín, Czech&nbsp;Republic'
      + ' &ensp;|&ensp;'
      + ' ID:&nbsp;07803575'
      + ' &ensp;|&ensp;'
      + ' VAT:&nbsp;CZ07803575'
      + ' &ensp;|&ensp;'
      + 'registered by the County Court in Brno under file number C&nbsp;110204'
    };

    return {
      text: text
    };
  },
  props: ['textLang']
};
</script>
