<template>
  <section>
    <v-layout align-center column wrap mx-3 my-5>

<!--
      ????Heading/Subheading 
      <v-flex sm4 xs12 class="my-3">
        <div class="text-xs-center">
          <h2 class="headline">{{ heading }}</h2>
          <span class="subheading">{{ subheading }}</span>
        </div>
      </v-flex>
-->

      <!-- Topics -->
      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout align-top row wrap>

            <v-flex md4 xs12 v-bind:key="topic.id" v-for="topic in topics">
              <v-card class="elevation-0 transparent animated">

                <!-- Icon -->
                <v-card-text class="text-xs-center">
                  <v-icon x-large class="semeai-purple-text text--lighten-2">
                    {{ topic.icon }}
                  </v-icon>
                </v-card-text>

                <!-- Topic Name -->
                <v-card-title primary-title class="justify-center layout">
                  <div class="display-1 text-xs-center font-m">{{ topic.name }}</div>
                </v-card-title>

                <!-- Topic Description -->
                <v-card-text>
                  <span class="font-s" v-html="topic.text"></span>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  props: ['textLang'],
  data () {
    let texts = {
      topics: {
        1: {
          name: {
            'cs': 'Analýza dat',
            'en': 'Data Insights'
          },
          text: {
            'cs': 'Vy máte spoustu dat a my víme, co s nimi. Pomůžeme vám v'
              + ' nich najít skryté vazby i vzorce chování zákazníků. Nejenže'
              + ' <span class="semeai-purple-text font-btn">rozumíme číslům</span>'
              + ', ale <span class="semeai-purple-text font-btn">dovedeme se ponořit i do textů</span> emailů'
              + ' a recenzí a dát je do souvislosti se zbytkem vašich dat.<br/><br/>'
              + ' Zhodnotíme základní charakteristiky vašich dat i jejich'
              + ' <span class="semeai-purple-text font-btn">kvalitu</span>. V úzké spolupráci s vámi zformulujeme hypotézy a'
              + ' otázky a díky posbíráným informacím je i potvrdíme nebo'
              + ' vyloučíme.<br/><br/>Během krátké doby vám poskyteneme <span class="semeai-purple-text font-btn">interaktivní'
              + ' reporty</span> a navrhneme jak se podle našich zjištění zachovat.',
            'en': 'Data is where the magic happens, but it takes a caring hand'
              + ' to bear ample fruit. Let us help you find ways to improve'
              + ' your business, hidden relationships and patterns. Not only'
              + ' can we <span class="semeai-purple-text font-btn">crunch'
              + '</span> your <span class="semeai-purple-text font-btn">numbers'
              + '</span>, we are able to <span class="semeai-purple-text'
              + ' font-btn">mine text</span> in your emails or reviews and make'
              + ' sense of it all for you.<br/><br/>We will evaluate general'
              + ' characteristics and <span class="semeai-purple-text'
              + ' font-btn">quality</span> of your data. In close cooperation'
              + ' with you, we will formulate hypotheses about your business'
              + ' and validate them with a deep dive into the collected data.'
              + '</br></br>In a short while, we will provide you with <span'
              + ' class="semeai-purple-text font-btn">interactive dashboards'
              + '</span> and proposals how to act on and monetize our findings.'
          }
        },
        2: {
          name: {
            'cs': 'Inteligentní prototypy a produkty',
            'en': 'Data-Driven Prototyping to Productization'
          },
          text: {
            'cs': 'Ať už potřebujete <span class="semeai-purple-text font-btn">'
              + 'prediktivní nástroje</span>, modely odlivu'
              + ' zákazníků a zaměstnanců nebo třeba generátor textu, vytvoříme'
              + ' nástroj šitý na míru vaší situaci. Nejdříve vám ale pomůžeme vyčíslit'
              + ' přínos takové služby.<br/><br/>Ve fázi prototypování prozkoumáme'
              + ' širokou škálu možností jak z technického hlediska, tak i z'
              + ' pohledu uživatele. Vytvoříme takové <span'
              + ' class="semeai-purple-text font-btn">prototypy</span>, které budou nejlépe'
              + ' splňovat vaše požadavky a buď si je do svého systému'
              + ' zintegrujete sami, nebo to necháte na nás.<br/><br/>My vám zaručíme,'
              + ' že bude hladce fungovat a bude <span class="semeai-purple-text'
              + ' font-btn">připravený na všechno</span>, co na'
              + ' něj pošlete, a přinese vám užitek.',
            'en': 'Be it <span class="semeai-purple-text font-btn">predictive'
              + ' tools</span>, churn models of your customers and employees,'
              + ' or a text generator, given your priorities we will not only'
              + ' design a custom service for you. First of all, we will help'
              + ' you evaluate the business value of said service.<br/><br/>The'
              + ' prototyping strategy focuses on the exploration of a wide'
              + ' range of technologies from both technical and user acceptance'
              + ' point of view. After you see the <span'
              + ' class="semeai-purple-text font-btn">prototypes</span> at'
              + ' work, you can decide to handle the productization yourself or'
              + ' leave the work to us.</br></br>We will make sure the service'
              + ' is integrated into your infrastructure, <span'
              + ' class="semeai-purple-text font-btn">ready to scale</span> and'
              + ' provide value.'
          }
        },
        3: {
          name: {
            'cs': 'Digitální inovace pro podniky',
            'en': 'Digital Innovation for Business'
          },
          text: {
            'cs': 'Máte skvělou firmu s <span class="semeai-purple-text'
              + ' font-btn">originálním produktem</span>, loajální'
              + ' zaměstnance a zákazníky, kteří se rádi vracejí. Ale něco tomu'
              + ' chybí.</br></br>Nezáleží na tom, jestli je to lepší IT infrastruktura,'
              + ' která ulehčí vašim zaměstnancům život, nebo sběr a analýza'
              + ' dat, abyste měli jistotu, že jsou vaši zákazníci vždy'
              + ' spokojení. Jste na správném místě. Pomůžeme vám najít ty'
              + ' <span class="semeai-purple-text font-btn">správné nástroje'
              + '</span> podle vašich potřeb. A pokud ještě'
              + ' neexistují, tak vám je na míru vytvoříme.</br></br>Vstupte společně s'
              + ' námi do světa <span class="semeai-purple-text font-btn">'
              + 'průmyslu 4.0</span> a nechte automatizované systémy'
              + ' pracovat pro vás.',
            'en': 'You have a great company with <span'
              + ' class="semeai-purple-text font-btn">brilliant products'
              + '</span>, loyal employees and awesome customers. But you feel'
              + ' there is something missing.</br></br>It doesn’t matter if'
              + ' it’s better IT infrastructure to make your employees’ lives'
              + ' easier or more precise collection of data to ensure your'
              + ' customers are always satisfied. You came to the right place.'
              + ' We will help you find the <span class="semeai-purple-text'
              + ' font-btn">right tools</span> for your goals. And yes, if they'
              + ' don’t exist yet we will create them for you.</br></br>This is'
              + ' the moment when <span class="semeai-purple-text font-btn">'
              + 'industry 4.0</span> stops being just a marketing phrase and'
              + ' turns your challenges into opportunities as it should.'
          }
        }
      }
    };

    return {
      heading: 'How we can help you',
      subheading: 'Offering consulting, analytic, and development services in'
      + ' the areas of Natural Language Processing, Machine Learning, and'
      + ' Data Analytics',
      topics: [
        {
          id: 1,
          icon: 'trending_up',
          name: texts.topics[1].name[this.textLang],
          text: texts.topics[1].text[this.textLang]
        },
        {
          id: 2,
          icon: 'build',
          name: texts.topics[2].name[this.textLang],
          text: texts.topics[2].text[this.textLang]
        },
        {
          id: 3,
          icon: 'flash_on',
          name: texts.topics[3].name[this.textLang],
          text: texts.topics[3].text[this.textLang]
        }
      ]
    };
  }
};
</script>

<style>
@keyframes animatedBackground {
  from {
    background-position: 0 2em;

  }
  to {
    background-position: 0;

  }
}

.animated:hover {
  background-image: linear-gradient(#7202e266, #FFFFFF);
  background-position: 0;
  animation: animatedBackground .1s linear;
  border-radius: 3%;
}
</style>