<template>
  <section>

    <v-layout align-center >
      <v-flex>
        <div class="text-xs-center">
          <span class="headline font-l" v-html="heading"></span>
        </div>
      </v-flex>
    </v-layout>

    <v-layout align-center >

            <v-container>
              <v-layout row wrap>
                <v-flex xs12 md6>
                  <v-card flat class="transparent">


                    <!-- Topic Name -->
                    <v-card-title primary-title class="justify-center layout">
                      <span class="isplay-1 text-xs-center font-m" v-html="topics[0].name"></span>
                    </v-card-title>

                    <!-- Topic Description -->
                    <v-card-text>
                      <span class="font-s" v-html="topics[0].text"></span>
                    </v-card-text>

                    
                  </v-card>

                  <v-layout align-center column justify-center>
                    <QuerysegDemoDialog  class="font-xs" :text="texts.tryDemo[textLang]" :textLang="textLang" :key="textLang"></QuerysegDemoDialog>
                  </v-layout>

                </v-flex>

                <v-flex xs12 md6>

                  <v-card flat class="transparent">


                    <!-- Topic Name -->
                    <v-card-title primary-title class="justify-center layout">
                      <span class="isplay-1 text-xs-center font-m" v-html="topics[1].name"></span>
                    </v-card-title>

                    <!-- Topic Description -->
                    <v-card-text>
                      <span class="font-s" v-html="topics[1].text"></span>
                    </v-card-text>

                    
                  </v-card>
                  <v-layout align-center column justify-center>
                    <QuerysimDemoDialog  class="font-xs" :text="texts.tryDemo[textLang]" :textLang="textLang" :key="textLang"></QuerysimDemoDialog>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>


    </v-layout>
  </section>
</template>

<script>
import ContactDialog from './ContactDialog.vue';
import QuerysegDemoDialog from './QuerysegDemoDialog.vue';
import QuerysimDemoDialog from './QuerysimDemoDialog.vue';

export default {
  components: {
    ContactDialog,
    QuerysegDemoDialog,
    QuerysimDemoDialog
  },
  props: ['textLang'],
  data () {
    let texts = {
      heading: {
        'cs': 'Produkty',
        'en': 'Products'
      },
      tryDemo: {
        cs: 'Vyzkoušejte si',
        en: 'Try Demo'
      },
      topics: {
        1: {
          name: {
            'cs': 'Query<span class="semeai-purple-text">Seg</span>',
            'en': 'Query<span class="semeai-purple-text">Seg</span>'
          },
          text: {
            'cs': 'Náš QuerySeg je schopný rozdělit české vyhledávací dotazy na logické celky.'
              + ' Navíc segmentům v dotazu přiřadí i kategorie jako třeba Zdraví nebo Fyzikální veličina.'
              + 'Naši zákazníci ho využívají k rozdělení vyhledávacích dotazů podle záměrů uživatele.',
            'en': 'QuerySeg is a great tool for segmentation of czech queries.'
              + ' On top of that QuerySeg can label segments in query with categories such as Health or Physical Quantity.'
              + ' Our customers use it primarily for categorization of queries with respect to intentions of users.'
          }
        },
        2: {
          name: {
            'cs': 'Query<span class="semeai-purple-text">Sim</span>',
            'en': 'Query<span class="semeai-purple-text">Sim</span>'
          },
          text: {
            'cs': 'Pro copywritery, ale především pro SEO experty je důležité vědět, na co a jak'
              + ' se lidé ptají Googlu nebo Seznamu. Správně pak při popisování webů zvolí slova,'
              + ' aby jejich stránky u vyhledávačů objevovaly na prvních místech.'
              + ' Právě jim jsou určeny produkty jako QuerySim.',
            'en': 'As a copywriter or SEO expert you need to know phrases people use'
              + ' when they are searching for you product.'
              + ' If you use the right words search engines will show your web page higher in their search results.'
              + ' Tools like QuerySim exist for this exact situation.'
          }
        }

      }
    };

    return {
      texts: texts,
      heading: texts.heading[this.textLang],
      topics: [
        {
          id: 1,
          name: texts.topics[1].name[this.textLang],
          text: texts.topics[1].text[this.textLang],

        },
        {
          id: 2,
          name: texts.topics[2].name[this.textLang],
          text: texts.topics[2].text[this.textLang],

        }

      ]
    };
  }
};
</script>