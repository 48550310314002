<template>
  <v-layout column wrap mx-3 my-5>
    <v-flex xs12>
      <v-container grid-list-xl>

        <v-card flat class="transparent">

          <!-- Headline -->
          <v-card-title primary-title class="justify-center layout">
            <div class="headline font-m">{{ contact[textLang] }}</div>
          </v-card-title>

          <!-- Text -->
          <v-card-text class="justify-center layout font-s">
            {{ description[textLang] }}
          </v-card-text>

          <v-layout justify-center>
            <!-- Contact Information -->
            <v-list class="transparent">
              <v-list-tile v-for="info in contactInfo" :key="info.id">

                <!-- Icon -->
                <v-list-tile-action>
                  <v-icon class="semeai-purple-text text--lighten-2">
                    {{ info.icon }}
                  </v-icon>
                </v-list-tile-action>

                <!-- Contact -->
                <v-list-tile-content>
                  <v-list-tile-title>
                    <span class="font-xs" v-html="info.content"></span>
                  </v-list-tile-title>
                </v-list-tile-content>

              </v-list-tile>
            </v-list>
          </v-layout>
        </v-card>
      </v-container>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  data () {
    let texts = {
      contact: {
        cs: 'Kontakt',
        en: 'Contact'
      },
      description: {
        cs: 'Jste připraveni nakopnout svůj byznys?',
        en: 'Ready to boost up your business?'
      },
      contactInfo: {
        1: {
          content: {
            cs: 'Brno, Česká republika',
            en: 'Brno, Czech Republic'
          }
        }
      }
    };

    return {
      contact: texts.contact,
      description: texts.description,
      contactInfo: [
        {id: 1, icon: 'place', content: texts.contactInfo[1].content[this.textLang]},
        {id: 2, icon: 'phone', content: '+420 732 448 742'},
        {
          id: 3,
          icon: 'email',
          content: '<a href="mailto:contact@semeai.cz">contact@semeai.cz</a>'
        },
      ]
    };
  },
  props: ['textLang']
};
</script>