// Third-party imports
import(/* webpackPreload: true */ 'material-design-icons-iconfont/dist/material-design-icons.css')
import(/* webpackPreload: true */ 'typeface-roboto-multilang/latin-ext.css')
import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import { load } from 'recaptcha-v3'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faLinkedin, faMedium } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faLinkedin)
library.add(faMedium)
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Project-specific imports
import {SITE_KEY} from './config';

Vue.config.productionTip = false

new Vue({render: function (h) {return h(App)}}).$mount('#app');

load(SITE_KEY).then((recaptcha) => {
  recaptcha.hideBadge()
  recaptcha.execute(SITE_KEY, {action: 'homepage'}).then(token => {
    // do nothing
  });
})
