<template>
  <v-dialog width="500" v-model="dialog" @keydown.esc="dialog = false">

      <!-- Dialog Opening Button -->
    <v-btn dark
           large
           class="semeai-purple-background font-btn lighten-2 mt-5 v-btn--round"
           slot="activator">
      {{ text }}
    </v-btn>

    <v-card>

      <v-card-title primary-title class="headline lighten-2">
        <img alt="semeai logo"
             class="dialog-headline"
             height="20em"
             src="../assets/logo.png"/>
        <span class="font-s">{{ headline }}</span>
      </v-card-title>

            <v-card-text>
              <span class="font-s" v-html="introduction"></span>
            </v-card-text>

      <v-container>
        <v-layout column justify-center>
          <!-- Message Subject -->
          <v-flex sm4 xs12>
            <v-text-field required
                          :label="texts.query[textLang]"
                          v-model="query"
                          class="font-xs"
                          v-on:keyup.enter="submit(query)">
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-container>

      <!-- Send Button -->
      <v-layout justify-center>
        <v-btn flat
             class="theme--light v-btn v-btn--flat v-btn--round font-xs font-btn"
             color="#7202e2 !important"
             @click="submit(query)"
             >
        {{ texts.send[textLang] }}
        </v-btn>
      </v-layout>





      <v-layout justify-center v-if="this.waiting===false">

        <table>
          <tbody>
            <td v-for="seg in notificationMsg.segmentation">
            {{ seg }}
            </td>
          </tbody>
        </table>
      </v-layout>

      <v-layout justify-center v-if="this.waiting===false">

        <table>
          <thead>
            <tr>
              <th class="text-left" v-for="head in tableHead">
              {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cat in segCategories">
              <td>{{ cat }}</td>
              <td>{{ notificationMsg.categories[cat].join(', ') }}</td>
            </tr>
          </tbody>
        </table>
      </v-layout>

      <v-container>
        <v-layout justify-center>
          <v-progress-circular
            v-if="this.waiting"
              indeterminate
              color="#7202e2"
              :size="70"
            ></v-progress-circular>
          </v-layout>
      </v-container>

      <v-layout justify-center>

        <v-card-text>
          <v-container>
            <v-layout column justify-center class="subheading">
              <span v-html="notification"></span>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-layout>

    </v-card>

  </v-dialog>
</template>

<script>
import axios from 'axios';

const HEADERS = {
  'Content-Type': 'application/json'};
const POST_URL = 'https://api.semeai.cz/queryseg-demo';
const NOTIF_ERR = {
  cs: 'Něco se pokazilo...',
  en: 'Something went wrong...'
};

export default {
  data () {
    let texts = {
      headline: {
        'cs': 'QuerySeg',
        'en': 'QuerySeg'
      },
      introduction: {
        'cs': 'Představte si, že něco hledáte na Seznamu, a napište to sem.'
          + ' V horní tabulce se Vám objeví segmentace dotazu na smysluplné části.'
          + ' Pod ní pak tabulka kategorií, do kterých lze dotaz zařadit podle významů slov.',
        'en': 'Imagine you are using any czech search engine and write a query.'
          + ' In the upper table you will see its segmentation into parts that make sense on its own.'
          + ' But there is more. Next table shows categories to which you can assign query depending on the meaning of the words.'
      },
      query: {
        cs: 'Dotaz',
        en: 'Query'
      },
      send: {
        cs: 'Odeslat',
        en: 'Send'
      },
      Category: {
        cs: 'Kategorie',
        en: 'Category'
      },
      Segment: {
        cs: 'Segment',
        en: 'Segment'
      }
    };
    return {

      query: '',
      texts: texts,
      headline: texts.headline[this.textLang],
      introduction: texts.introduction[this.textLang],
      notificationMsg: '',
      notification: '',
      tablehead: [texts.Category[this.textLang], texts.Segment[this.textLang]],
      waiting: false,

      submit: (query) => {
        this.waiting = true

        const DATA = {
          "batchId":"1234",
          "queries":[query]
        };

        axios.post(POST_URL, DATA, {headers: HEADERS})
          .then (response => {
            this.notificationMsg = response.data.result[0]
            this.segCategories = Object.keys(response.data.result[0].categories)
            this.tableHead = this.tablehead
            this.notification = ''
            this.waiting = false
          })
          .catch (error => {
            this.notification = NOTIF_ERR[this.textLang];
            this.notificationMsg = 'NOTIF_ERR_MSG[this.textLang]';
            this.segCategories = []
            this.tableHead = []
            this.waiting = false
          });
      }
    };
  },
  props: ['text','textLang']
};
</script>


<style>
table {
  font-family:Montserrat !important;
  width: 90%;
  border-collapse: collapse;
  border: 3px solid #7202e2 !important;
  margin: 10px 10px 0 10px;
}

table th {
  text-transform: uppercase;
  text-align: left;
  background: #7202e2 !important;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

table td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7202e2 !important;
}
table td:last-child {
  border-right: none;
}
table tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}
</style>
