<template>
  <v-dialog width="500" v-model="dialog" @keydown.esc="dialog = false">

    <!-- Dialog Opening Button -->
    <v-btn dark
           large
           class="semeai-purple-background font-btn lighten-2 mt-5 v-btn--round"
           slot="activator">
      {{ text }}
    </v-btn>

    <!-- Dialog Body -->
    <v-card>

      <!-- Dialog Headline -->
      <v-card-title primary-title class="headline lighten-2">
        <img alt="semeai logo"
             class="dialog-headline"
             height="20em"
             src="../assets/logo.png"/>
        <span class="font-s">{{ texts.headline[textLang] }}</span>
      </v-card-title>

      <!-- Dialog Form -->
      <v-card-text>
        <v-form ref="form">
          <v-container>
            <v-layout column justify-center>

              <!-- Response Email -->
              <v-flex md4 xs12>
                <v-text-field required
                              label="E-mail"
                              v-model="email"
                              class="font-xs"
                              :rules="emailRules">
                </v-text-field>
              </v-flex>

              <!-- Message Subject -->
              <v-flex md4 xs12>
                <v-text-field required
                              :label="texts.subject[textLang]"
                              v-model="subject"
                              class="font-xs"
                              :rules="subjectRules">
                </v-text-field>
              </v-flex>

              <!-- Message Content -->
              <v-flex md4 xs12>
                <v-textarea multiline
                            required
                            solo
                            :label="texts.message[textLang]"
                            v-model="message"
                            class="font-xs"
                            :rules="messageRules">
                </v-textarea>
              </v-flex>

              <!-- reCAPTCHA Notice -->
              <span class="grey--text text-xs-left font-xs" v-html="texts.notice[textLang]">

              </span>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>

      <!-- Control Buttons -->
      <v-card-actions>
        <!-- Cancel Button -->
        <v-btn flat
               class="theme--light v-btn v-btn--flat v-btn--round font-xs font-btn"
               @click.native="dialog = false">
          {{ texts.close[textLang] }}
        </v-btn>

        <v-spacer></v-spacer>

        <!-- Send Button -->
        <v-btn flat
               class="theme--light v-btn v-btn--flat v-btn--round font-xs font-btn"
               color="primary"
               @click="submit(email, subject, message)"
               :disabled="!isValid">
          {{ texts.send[textLang] }}
        </v-btn>

        <!-- Result Notice -->
        <v-dialog width="500" @keydown.esc="notify = false" v-model="notify">
          <v-card>

            <!-- Notice Headline -->
            <v-card-title primary-title class="headline lighten-2">
              <!-- Notice Icon -->
              <v-icon large class="blue--text text--lighten-2">
                {{ notificationIcon }}
              </v-icon>
              &nbsp;

              <!-- Notice Headline Text -->
              <span v-html="notification"></span>
            </v-card-title>

            <!-- Notice Text -->
            <v-card-text>
              <v-container>
                <v-layout column justify-center class="subheading">
                  <span v-html="notificationMsg"></span>
                </v-layout>
              </v-container>
            </v-card-text>

            <!-- Close Notice Button -->
            <v-card-actions class="justify-center">
              <v-btn flat
                     class="theme--light v-btn v-btn--flat v-btn--round"
                     @click.native="notify = false">
                {{ texts.close[textLang] }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
// Project-specific imports
import {SITE_KEY} from '../config';
import axios from 'axios';

// CONSTANTS DEFINITIONS

const HEADERS = {
  'x-api-key': '8Vg9MXOfIZ8VACYhuXWW6QBMSru0p259fcwpzgxj',
  'Content-Type': 'application/json',
};
const POST_URL = 'https://api.semeai.cz/mail-proxy';

// Validation messages
const INVALID_EMAIL_WARNING = {
  cs: 'Toto nevypadá jako platná e-mailová adresa.',
  en: 'E-mail address looks invalid.'
};

const MISSING_EMAIL_WARNING = {
  cs: 'Uveďte prosím svůj kontaktní e-mail.',
  en: 'Please provide your contact e-mail.'
};

const MISSING_SUBJECT_WARNING = {
  cs: 'Uveďte prosím předmět zprávy.',
  en: 'Please provide the subject.'
};

const MISSING_MESSAGE_WARNING = {
  cs: 'Vyplňte prosím obsah zprávy.',
  en: 'Please provide the message.'
};

// Notifiction message headlines
const NOTIF_SENDING = {
  cs: 'Odesílám...',
  en: 'Sending...'
};

const NOTIF_SENT = {
  cs: 'Zpráva je na cestě!',
  en: 'Message is on the way!'
};

const NOTIF_ERR = {
  cs: 'Něco se pokazilo...',
  en: 'Something went wrong...'
};

// Notification messages
const NOTIF_SENDING_MSG = {
  cs: 'Počkejte prosím chviličku na potvrzení, než zavřete toto okno.',
  en: 'Please wait a few seconds for confirmation before'
    + ' closing this dialog.'
}

const NOTIF_SENT_MSG = {
  cs: 'Co nejdříve se Vám ozveme. Mezitím se můžete třeba podívat na náš'
    + ' <a href="http://medium.com/@vojtech.krajnansky" target="__blank">blog</a>!',
  en: 'You will be hearing from us shortly. In the meantime,'
    + ' feel free to check out our <a'
    + ' href="http://medium.com/@vojtech.krajnansky" target="__blank">blog</a>!'
};

const NOTIF_ERR_MSG = {
  cs: 'Omlouváme se, zprávu se nepodařilo odeslat! Obraťte se na nás prosím'
    + ' přímo skrze e-mail: <a href="mailto:contact@semeai.cz" target="__blank">'
    + 'contact@semeai.cz</a>',
  en: 'We are sorry, but the message could not be delivered!'
  + ' Please contact us directly via e-mail:'
  + ' <a href="mailto:contact@semeai.cz" target="__blank">'
  + 'contact@semeai.cz</a>'
};


// FUNCTION DEFINITIONS

/**
 * Validates that a field complies with all rules imposed upon it.
 *
 * @param {string} field - The field whose compliance to validate.
 * @param {array(string => boolean | string)} rules - The rules against which
 *                                                    to validate.
 *
 * @return {boolean} true when the field complies with the rules, false
 *                   otherwise.
 */
function validate(field, rules) {
  return rules.every(rule => rule(field) === true);
}

export default {
  props: [ 'text', 'textLang' ],
  computed: {
    isValid: function () {
      return validate(this.email, this.emailRules)
        && validate(this.subject, this.subjectRules)
        && validate(this.message, this.messageRules);
    }
  },
  data () {
    let texts = {
      headline: {
        cs: 'Pošlete nám zprávu!',
        en: 'Send us a message!'
      },
      subject: {
        cs: 'Předmět',
        en: 'Subject'
      },
      message: {
        cs: 'Zpráva',
        en: 'Message'
      },
      notice: {
        cs: 'Tato stránka je chráněna technologií reCAPTCHA a vztahují se na ni'
          + ' <a href="https://policies.google.com/terms">Podmínky služeb</a> a'
          + ' <a href="https://policies.google.com/privacy">Zásady ochrany osobních údajů</a>'
          + ' Google.',
        en: 'This site is protected by reCAPTCHA and the Google'
          + ' <a href="https://policies.google.com/privacy">Privacy Policy</a>'
          + ' and <a href="https://policies.google.com/terms">Terms of Service</a>'
          + ' apply.'
      },
      close: {
        cs: 'Zavřít',
        en: 'Close'
      },
      send: {
        cs: 'Odeslat',
        en: 'Send'
      }
    };

    return {
      // Dialog and Notification dialog existence state
      dialog: false,
      notify: false,

      // Form contents
      email: '',
      subject: '',
      message: '',

      texts: texts,

      // Form validation rules
      emailRules: [
        email => !!email || MISSING_EMAIL_WARNING[this.textLang],
        email => /.+@.+\..+/.test(email) || INVALID_EMAIL_WARNING[this.textLang]
      ],
      subjectRules: [subject => !!subject || MISSING_SUBJECT_WARNING[this.textLang]],
      messageRules: [message => !!message || MISSING_MESSAGE_WARNING[this.textLang]],

      // Result notification dialog data
      notification: '',
      notificationIcon: '',
      notificationMsg: '',

      // Form submission
      submit: (email, subject, message) => {
        this.notify = true;

        this.notificationIcon = 'send';
        this.notification = NOTIF_SENDING[this.textLang];
        this.notificationMsg = NOTIF_SENDING_MSG[this.textLang];

        grecaptcha.ready(() => {
          grecaptcha
            .execute(SITE_KEY, {action: 'sendMessage'})
            .then(token => {
              const DATA = {
                'email': encodeURI(email),
                'subject': encodeURI(subject),
                'message': encodeURI(message),
                'token': token
              };

              axios.post(POST_URL, DATA, {headers: HEADERS})
                .then (response => {
                  this.notificationIcon = 'mood';
                  this.notification = NOTIF_SENT[this.textLang];
                  this.notificationMsg = NOTIF_SENT_MSG[this.textLang];
                })
                .catch (error => {
                  this.notificationIcon = 'mood_bad';
                  this.notification = NOTIF_ERR[this.textLang];
                  this.notificationMsg = NOTIF_ERR_MSG[this.textLang];
                });
            });
        });

        this.dialog = false;
      }
    };
  }
};
</script>

<style>
  .dialog-headline {
    margin-right: 1em
  }
</style>
