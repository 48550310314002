<template>
  <v-app>

    <!-- Toolbar -->
    <SectionToolbar :key="textLang" v-bind:textLang="textLang" @changeLanguage="changeLanguage"></SectionToolbar>

    <div class="upper-margin"></div>

    <v-content>


      <SectionIntro id="sectionIntro" v-bind:textLang="textLang"></SectionIntro>
      <SectionAbout id="sectionAbout" v-bind:textLang="textLang" :key="textLang"></SectionAbout>
      <SectionPackages id="sectionPackages" v-bind:textLang="textLang" :key="textLang"></SectionPackages>
      <SectionProducts id="SectionProducts" :key="textLang" v-bind:textLang="textLang"></SectionProducts>
      <SectionTeam id="SectionTeam" :key="textLang" v-bind:textLang="textLang"></SectionTeam>
      <SectionHiring id="sectionHiring" v-bind:textLang="textLang"></SectionHiring>
      <SectionContactInfo id="sectionContactInfo" :key="textLang" v-bind:textLang="textLang"></SectionContactInfo>
      <SectionBlogFeed id="sectionBlogFeed" v-bind:textLang="textLang"></SectionBlogFeed>
      <SectionFooter id="sectionFooter" v-bind:textLang="textLang"></SectionFooter>
      <ChatBot id="chatBot"></ChatBot>

    </v-content>
  </v-app>
</template>

<script>
// Project-specific imports
import SectionToolbar from './components/SectionToolbar.vue'
import SectionIntro from './components/SectionIntro.vue'
import SectionAbout from './components/SectionAbout.vue'
import SectionPackages from './components/SectionPackages.vue'
import SectionTeam from './components/SectionTeam.vue'
import SectionProducts from './components/SectionProducts.vue'
import SectionHiring from './components/SectionHiring.vue'
import SectionContactInfo from './components/SectionContactInfo.vue'
import SectionFooter from './components/SectionFooter.vue'
import SectionBlogFeed from './components/SectionBlogFeed.vue'
import ChatBot from './components/ChatBot.vue'


export default {
  name: 'App',
  components: {
    ChatBot,
    SectionToolbar,
    SectionIntro,
    SectionAbout,
    SectionPackages,
    SectionTeam,
    SectionProducts,
    SectionHiring,
    SectionContactInfo,
    SectionFooter,
    SectionBlogFeed
  },
  data () {
    return {
      textLang: 'en'
    };
  },
  destroyed() {
    window.removeEventListener('resize', this.setSmoothScroll)
  },
  mounted () {
    window.addEventListener('resize', this.setSmoothScroll);
    this.setSmoothScroll();
  },
  updated () {
    this.setSmoothScroll();
  },
  methods: {
    setSmoothScroll() {
      document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (evnt) {
          evnt.preventDefault();

          const TOOLBAR_HEIGHT = document.querySelector('.v-toolbar__content')
          .offsetHeight;
          const ELEMENT_TOP = document.querySelector(this.getAttribute('href'))
          .getBoundingClientRect()
          .top;
          const BODY_TOP = document.body.getBoundingClientRect().top;

          window.scrollTo({
            top: ELEMENT_TOP - BODY_TOP - TOOLBAR_HEIGHT,
            left: 0,
            behavior: 'smooth'
          })
        });
      });
    },
    changeLanguage(value) {
      this.textLang = value;

    }
  }
}
</script>

<style>
.main-logo {
  cursor: pointer;
}

.upper-margin {
  margin-top: 3em;
}

.font-xl {
  font-family: Montserrat !important;
  font-size: calc(4em + 1vw) !important;
  line-height: 1em !important;
}

.font-l {
  font-family: Montserrat !important;
  font-size: calc(2em + 1vw) !important;
  line-height: 1em !important;
}

.font-m {
  font-family: Montserrat !important;
  font-size: calc(1.2em + .75vw) !important;
  line-height: 1em !important;
}

.font-s {
  font-family: Montserrat !important;
  font-size: calc(1em + .25vw) !important;
  line-height: 1.5em !important;
}

.font-xs {
  font-family: Montserrat !important;
  font-size: calc(.8em + .2vw) !important;
  line-height: 1em !important;
}

.font-xxs {
  font-family: Montserrat !important;
  font-size: calc(.6em + .1vw) !important;
  line-height: 1em !important;
}

.font-btn {
  font-weight: bold
}

.semeai-purple-text {
  color: #7202e2 !important;
  caret-color: #7202e2 !important;
}

.semeai-purple-background {
  background-color: #7202e2 !important;
}
</style>
