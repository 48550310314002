<template>
  <div>
    <beautiful-chat
      :titleImageUrl="titleImageUrl"
      :onMessageWasSent="onMessageWasSent"
      :messageList="messageList"
      :newMessagesCount="newMessagesCount"
      :isOpen="isChatOpen"
      :close="closeChat"
      :open="openChat"
      :showEmoji="false"
      :participants="participants"
      :showFile="false"
      :sendMessage="sendMessage"
      :colors="colors"/>

  </div>
</template>
<script>
// Third-party imports
import Chat from 'vue-beautiful-chat';
import Vue from 'vue';
import axios from 'axios';



const POST_URL = 'https://dialogflow.googleapis.com/v2/projects/sembot-ccnkdo/agent/sessions/12345:detectIntent';
const TOKEN_URL = 'https://api.semeai.cz/gcp-auth'

Vue.use(Chat);

export default {
  components: {
    Chat
  },
  data() {
    return {
      headersChat :  { 'Content-Type': 'application/json' },
      messageList: [],
      newMessagesCount: 0,
      isChatOpen: false,
      isHiSent: false,
      bearer: '',
      //titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      participants: [
        {
          id: 'sembot',
          name: 'SemBot',
          imageUrl: 'https://semeai.cz/img/logo.b3db7319.png'
        }
      ],
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#7202e2',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      } 
    }
  },
  methods: {
    sendMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'me', type: 'text', data: { text } })
      }
    },

    recieveMessage (text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({ author: 'sembot', type: 'text', data: { text } })
      }
    },

    onMessageWasSent (msg) {
      if (msg.data.text.trim().length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.messageList.push(msg)
      }
      if (msg.author === 'me') {
        const DATA = {
          "session":"test",
          "queryInput": {
            "text": {
              "text": msg.data.text,
              "languageCode": "en-Us"
            }
          }
        };
        //axios.defaults.headers.common['Authorization'] = this.bearer
        //this.HEADERS.Authorization = this.bearer
        axios.post(POST_URL, DATA, {headers: this.headersChat})
          .then (response => {
            this.recieveMessage(response.data.queryResult.fulfillmentText)
          })    
      }
    },

    openChat () {
      this.isChatOpen = true
      this.newMessagesCount = 0
      if (this.isHiSent === false) {
        axios.get(TOKEN_URL)
          .then (response => {
            this.bearer = 'Bearer '.concat(response.data.bearerToken)
            this.initializeChat()
            this.isHiSent = true
          })      
      }
    },

    initializeChat () {
      const DATA = {
        "session":"test",
        "queryInput": {
          "text": {
            "text": "hi",
            "languageCode": "en-Us"
          }
        }
      };
      //axios.defaults.headers.common['Authorization'] = this.bearer
      this.headersChat.Authorization = this.bearer
      axios.post(POST_URL, DATA, {headers: this.headersChat})
        .then (response => {
          this.recieveMessage(response.data.queryResult.fulfillmentText)
        })
    },

    closeChat () {
      this.isChatOpen = false
    }
  }
}
</script>
<style>
.sc-open-icon {
  background: #7202e2 !important;
  border-radius: 30%;
}

.sc-closed-icon {
  background: #7202e2 !important;
  border-radius: 30%;
}

.sc-header {
  background-color: #7202e2 !important;
}

.sc-message--text-content {
  margin: 0;
}

.sc-user-input--buttons {
    position: absolute;
    right: 0;
    height: 100%;
    display: flex;
}

.sc-user-input--text {
    width: 22em;
}
</style>