<template>
  <v-toolbar class="app-bar elevation-10 v-toolbar--fixed">

    <!-- Title -->
    <v-toolbar-title class="display-1 main-logo" onclick="window.scrollTo({top: 0, left: 0, behavior: 'smooth'})">
      <img alt="logo" height="20em" src="@/assets/logo.png" />
      <span class="font-s toolbar-title semeai-purple-text">{{ texts.companyName[textLang] }}</span>
    </v-toolbar-title>

    <v-spacer />

    <!-- Menu -->

    <a class="theme--light v-btn v-btn--flat v-btn--round font-btn" href="#sectionAbout">
      <div class="v-btn__content font-xs">{{ texts.about[textLang] }}</div>
    </a>

    <a class="theme--light v-btn v-btn--flat v-btn--round font-btn" href="http://medium.com/@vojtech.krajnansky" target="__blank">
      <div class="v-btn__content font-xs">{{ texts.blog[textLang] }}&nbsp;
        <v-icon small class="semeai-purple-text text--lighten-2">open_in_new</v-icon>
      </div>
    </a>

    <a class="theme--light v-btn v-btn--flat v-btn--round font-btn" href="#sectionContactInfo">
      <div class="v-btn__content font-xs">{{ texts.contact[textLang] }}</div>
    </a>

      <img class="lang-flag" v-on:click="changeTextLanguage" :src="langIcon" height="30px" width="30px" />
  </v-toolbar>
</template>

<script>
// We need to resolve webpack dynamic path binding for the image assets
// EngFlag, CsFlag are now strings of the resolved path in the built code
import EngFlag from '../assets/gb.svg';
import CsFlag from '../assets/cs.svg';

export default {
  data () {
    let langIcon;
    if (this.textLang === 'cs') {
      langIcon = EngFlag;
    } else {
      langIcon = CsFlag;
    }

    const texts = {
      companyName: {
        cs: 'semeai',
        en: 'semeai'
      },
      about: {
        cs: 'O Nás',
        en: 'About'
      },
      blog: {
        cs: 'Blog',
        en: 'Blog'
      },
      contact: {
        cs: 'Kontakt',
        en: 'Contact'
      }
    };

    return {
      langIcon: langIcon,
      texts: texts
    };
  },
  methods: {
    changeTextLanguage (event) {
      let newLang;

      if (this.textLang === 'cs') {
        newLang = 'en';
      } else {
        newLang = 'cs';
      }
      this.$emit('changeLanguage', newLang);
    }
  },
  props: ['textLang']
}
</script>

<style>
.toolbar-title {
  margin-left: .4em;
  font-weight: bold;
}

.lang-flag {
  cursor: pointer;
  margin-left: .8em;
}
</style>