<template>
  <v-flex sm12>
    <v-card flat class="transparent">
      <v-card-title primary-title class="justify-center layout">
        <div class="headline font-m">{{ texts.headline[textLang] }}</div>
      </v-card-title>
      <v-layout justify-center mx-3 px-3>
        <div id="medium-widget" />
      </v-layout>
    </v-card>
  </v-flex>
</template>

<script>
export default {
  data () {
    let texts = {
      headline: {
        cs: 'Navštivte náš blog',
        en: 'Visit our blog'
      }
    };

    return {
      texts: texts
    };
  },
  props: ['textLang']
};
</script>