<template>
  <v-dialog width="500" v-model="dialog" @keydown.esc="dialog = false">

      <!-- Dialog Opening Button -->
    <v-btn dark
           large
           class="semeai-purple-background font-btn lighten-2 mt-5 v-btn--round"
           slot="activator">
      {{ text }}
    </v-btn>

    <v-card>

      <v-card-title primary-title class="headline lighten-2">
        <img alt="semeai logo"
             class="dialog-headline"
             height="20em"
             src="../assets/logo.png"/>
        <span class="font-s">{{ headline }}</span>
      </v-card-title>


            <v-card-text>
              <span class="font-s" v-html="introduction"></span>
            </v-card-text>


      <v-container>
        <v-layout column justify-center>
          <!-- Message Subject -->
          <v-flex sm4 xs12>
            <v-text-field required
                          :label="texts.keywords[textLang]"
                          v-model="keywords"
                          class="font-xs"
                          v-on:keyup.enter="submit(keywords)">
            </v-text-field>
          </v-flex>
        </v-layout>




        <v-layout column>
          <v-flex xs4>
            <span class="font-s" v-html="tryKeywords"></span>

            <v-btn rounded
              class="theme--light v-btn v-btn--flat v-btn--small v-btn--round font-xs font-btn"
              @click="keywords = preKeyword.text"
              color="#D3D3D3"
              v-bind:key="preKeywords.id" v-for="preKeyword in preKeywords">
              {{ preKeyword.text }}
            </v-btn>
          </v-flex>




        </v-layout>
      </v-container>

      <!-- Send Button -->
      <v-layout justify-center>

        <v-btn flat
             class="theme--light v-btn v-btn--flat v-btn--round font-xs font-btn"
             color="#7202e2 !important"
             @click="submit(keywords)"
             >
        {{ texts.send[textLang] }}
        </v-btn>
      </v-layout>

      <v-layout justify-center>
        <table v-if="this.waiting===false">
          <tbody>
            <tr v-for="query in notificationMsg.slice(0,15)">
              <td>{{ query[0] }}</td>
            </tr>
          </tbody>
        </table>
      </v-layout>

      <v-container>
        <v-layout justify-center>
          <v-progress-circular
            v-if="this.waiting"
              indeterminate
              color="#7202e2"
              :size="70"
            ></v-progress-circular>
          </v-layout>
      </v-container>

      <v-card-text>
        <v-container>
          <v-layout column justify-center class="subheading">
            <span v-html="notification"></span>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>


  </v-dialog>
</template>

<script>
import axios from 'axios';
import queries_json from '../assets/cosmetics_queries.json'

const HEADERS = {
  'Content-Type': 'application/json'};
const POST_URL = 'https://api.semeai.cz/querysim-demo';
const NOTIF_ERR = {
  cs: 'Něco se pokazilo...',
  en: 'Something went wrong...'

};
const queries = queries_json

export default {
  data () {
    let texts = {
      headline: {
        'cs': 'QuerySim',
        'en': 'QuerySim'
      },
      introduction: {
        'cs': ' Vžijte se do role člověka, který má za úkol vymyslet popisky e-shopu s kosmetikou,'
          + ' tomu jsme přizpůsobili naši databázi vyhledávacích dotazů.'
          + ' Zadejte klíčová slova a fráze (sůl do koupele, suchá pleť) a podívejte se na deset dotazů,'
          + ' které k nim mají semanticky nejblíže.'
          + ' Zkušený copywriter potom formulace z dotazů využívá při ladění obsahu webu.',
        'en': ' Imagine you are copywriter at czech eshop with cosmetics'
          + ' - this is the task our demo and database are prepared for.'
          + ' Input kewords and phrases (sůl do koupele, suchá pleť)'
          + '  and take a look at ten semanticly closest queries to them.'
      },
      keywords: {
        cs: 'Klíčová slova',
        en: 'Keywords'
      },
      tryKeywords: {
        cs: 'Zkuste například: ',
        en: 'Try out: '
      },
      send: {
        cs: 'Odeslat',
        en: 'Send'
      },
      preKeywords: {
        1: {
          cs: 'vrásky, makeup',
          en: 'vrásky, makeup'
        },
        2: {
          cs: 'líčení, obličej',
          en: 'líčení, obličej'
        },
        3: {
          cs: 'suchá pleť',
          en: 'suchá pleť'
        },
        4: {
          cs: 'dlouhotrvající efekt, permanentní',
          en: 'dlouhotrvající efekt, permanentní'
        }

      }

    };
    return {
      keywords: '',
      texts: texts,
      headline: texts.headline[this.textLang],
      introduction: texts.introduction[this.textLang],
      notificationMsg: '',
      notification: '',
      tryKeywords: texts.tryKeywords[this.textLang],
      waiting: false,

      preKeywords: [
        {
          id: 1,
          text: texts.preKeywords[1][this.textLang],
        },
        {
          id: 2,
          text: texts.preKeywords[2][this.textLang]
        },
        {
          id: 3,
          text: texts.preKeywords[3][this.textLang]
        },
        {
          id: 4,
          text: texts.preKeywords[4][this.textLang]
        }
      ],


      submit: (keywords) => {
        this.waiting = true

        const DATA = {
          "batchId":"1234",
          "simFunc":"mean",
          "idfWeight":"arithmetic-mean",
          "keywords":[keywords.replace(/,/g, " ")],
          "queries": queries
        };

        axios.post(POST_URL, DATA, {headers: HEADERS})
          .then (response => {
            this.notificationMsg = response.data.sims
            this.notification = ''
            this.waiting = false

          })
          .catch (error => {
            this.notification = NOTIF_ERR[this.textLang];
            this.waiting = false
          });
      }
    };
  },
  props: ['text','textLang']
};
</script>


<style>
table {
  font-family:Montserrat !important;
  width: 90%;
  border-collapse: collapse;
  border: 3px solid #7202e2 !important;
  margin: 10px 10px 0 10px;
}

table th {
  text-transform: uppercase;
  text-align: left;
  background: #7202e2 !important;
  color: #FFF;
  padding: 8px;
  min-width: 30px;
}

table td {
  text-align: left;
  padding: 8px;
  border-right: 2px solid #7202e2 !important;
}
table td:last-child {
  border-right: none;
}
table tbody tr:nth-child(2n) td {
  background: #D4D8F9;
}
</style>
