<template>
  <section>
    <!-- Background Image -->
    <v-parallax height="600"
                :src="require('@/assets/ash-edmonds-636860-unsplash-sm.webp')">

      <v-layout align-center
                column
                justify-center
                class="white--text">

        <!-- Company Logo -->
        <img alt="semeai logo" height="48em" src="../assets/logo.png" />

        <!-- Company Title -->
        <h1 class="display-3 mb-2 text-xs-center white--text font-xl">{{ texts.companyName[textLang] }}</h1>

        <!-- Motto -->
        <div class="headline mb-3 text-xs-center font-l" v-html="texts.motto[textLang]">
        </div>

        <!-- Contact Button -->
        <ContactDialog class="font-xs" :text="texts.contactUs[textLang]" :textLang="textLang" :key="textLang"></ContactDialog>
      </v-layout>
    </v-parallax>
  </section>
</template>

<script>
// Project-specific imports
import ContactDialog from './ContactDialog.vue';

export default {
  components: {
    ContactDialog
  },
  data () {
    const texts = {
      companyName: {
        cs: 'semeai',
        en: 'semeai'
      },
      motto: {
        cs: 'odkryjte hodnotu svých <span class="semeai-purple-text">dat</span>',
        en: 'discover the value of <span class="semeai-purple-text">your data</span>'
      },
      contactUs: {
        cs: 'Kontaktujte Nás',
        en: 'Contact Us'
      }
    };

    return {
      texts: texts
    };
  },
  props: ['textLang']
};
</script>