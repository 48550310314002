<template>
  <section>
    <v-layout align-center column wrap mx-3 my-5>

      <!-- Heading/Subheading -->
      <v-flex sm4 xs12 class="my-3">
        <div class="text-xs-center">
          <span class="headline font-l" v-html="heading"></span>
          </br>
          <span class="subheading font-s">{{ subheading }}</span>
        </div>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  data () {
    let texts = {
      heading: {
        'cs': 'Jak můžeme pomoci i <span class="semeai-purple-text">vám</span>',
        'en': 'How we can help <span class="semeai-purple-text">you</span>'
      },
      subheading: {
        'cs': 'Nabízíme konzultační, analytické a vývojové služby v oblastech Zpracování Přirozeného Jazyka, Strojového Učení a Analýzy Dat',
        'en': 'Offering consulting, analytic, and development services in'
          + ' the areas of Natural Language Processing, Machine Learning, and'
          + ' Data Analytics'
      },
    };

    return {
      heading: texts.heading[this.textLang],
      subheading: texts.subheading[this.textLang],
    };
  },
  props: ['textLang']
};
</script>