<template>
  <section>
    <v-layout align-center column wrap mx-3 my-5>
      <v-flex sm4 xs12 class="my-3">
        <div class="text-xs-center">
          <span class="headline font-l" v-html="team"></span>
        </div>
      </v-flex>

      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout align-top row wrap>
            <v-flex md4 xs12 v-for="profile in personProfiles" :key="profile.id">
              <v-card class="elevation-0 transparent">


                <!-- Headline -->
                <v-card-title class="justify-left layout">
                  <!-- Photo -->
                  <v-list-tile-avatar>
                    <img :alt="profile.avatar" :src="profile.avatar" />
                  </v-list-tile-avatar>
                  <div class="headline font-m">{{ profile.name }}</div>
                </v-card-title>

                <div class="divider"></div>

                <!-- Content -->
                <v-card-text class="justify-left layout">
                  <div class="font-xs">{{ profile.title }}
                    <span v-if="profile.link">
                      <a :href="profile.link" target="__blank">
                        <font-awesome-icon class="fa-lg semeai-purple-text text--lighten-2" :icon="{ prefix: 'fab', iconName: 'linkedin' }" />
                      </a>
                    </span>
                    <span v-else>
                      &nbsp;
                    </span>
                  </div>
                </v-card-text>
                <v-card-text class="justify-left layout">
                  <div class="font-s" v-html="profile.bio">

                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
  
    </v-layout>
  </section>
</template>

<script>
export default {
  props: ['textLang'],
  data () {
    let texts = {
      headline: {
        'cs': 'Náš tým',
        'en': 'Our Team'
      },
      personProfiles: {
        1: {
          title: {
            'cs': 'Zakladatel',
            'en': 'Co-founder'
          },
          bio: {
            'cs': '„Můj zájem o umělou inteligenci pramení z vášně pro vědu a'
              + ' filozofii. Inspiruje mě, jak může proměnit každý aspekt'
              + ' našeho života. Jsem přesvědčený o tom, že ve svých důsledcích'
              + ' lidstvu prospěje, když bude dostupná pro každého. Velké a'
              + ' malé firmy ale i jednotlivci by měli mít možnost přispívat a'
              + ' čerpat z technologického pokroku. Doufám, že právě se semeai'
              + ' k takové budoucnosti pomůžeme směřovat.“',
            'en': '“My interest in artificial intelligence stems from my passion'
              + ' for science and philosophy. I draw inspiration by imagining'
              + ' how AI could revolutionize every aspect of human life. I am'
              + ' convinced that for its social impact to be ultimately'
              + ' beneficial to humankind, AI technology must become available'
              + ' to everyone.<br/><br/>Large and small companies, even individuals'
              + ' alike should be able to contribute and benefit from'
              + ' technological advancements. With semeai, I hope to add our'
              + ' own piece towards a better future.”'
          }
        },
        2: {
          title: {
            'cs': 'Zakladatel',
            'en': 'Co-founder'
          },
          bio: {
            'cs': '„Zajímám se o to, jak spolu technologie a lidé spolupracují. Věřím,'
              + ' že technologie je tu od toho, aby lidem pomáhala, a pokud je'
              + ' využita zodpovědně, může přispět ke kvalitě života každého z'
              + ' nás. Proto jsme zakládali semeai, abychom zpřístupnili plody'
              + ' technologického pokroku každému, kdo je může potřebovat. A'
              + ' také proto se na Masarykově univerzitě podílím na výzkumu'
              + ' detekce rakovinných buněk.“',
            'en': '“I am all about the space between people and technology.'
              + ' I believe that technology should help alleviate people’s'
              + ' problems, and when used responsibly, can improve our overall'
              + ' lives.<br/><br/>That’s why I co-founded semeai - to make sure we use'
              + ' the powerful capabilities becoming available to everyone in a'
              + ' productive and positive way. It’s also why I participate in'
              + ' research at the Masaryk University in Brno - to explore new'
              + ' ways we can solve troubling problems with intelligent systems.”'
          }
        },
        3: {
          title: {
            'cs': 'Data Scientist',
            'en': 'Data Scientist'
          },
          bio: {
            'cs': '„Cestu ke strojovému učení jsem si našel skrz matematiku,'
              + ' když jsem se postupně od záliby v teoretických konceptech'
              + ' dostal k řešení praktických problémů. Myslím si, že výzvy,'
              + ' kterým s příchodem umělé inteligence čelíme, nejsou a nebudou'
              + ' jen technologické a obchodní povahy. Proto věnuji svůj čas a'
              + ' pozornost i etickým a vzdělávácím otázkám, které s sebou'
              + ' takový pokrok přináší.“',
            'en': '“I found my way into machine learning through math and'
              + ' statistics, which taught me to enjoy solving complex tasks.'
              + ' Moreover, working as a journalist I developed a sense of'
              + ' responsibility to the public.<br/><br/>I believe there are missing'
              + ' links in communication between the public and professionals,'
              + ' which is why I want to help tackle not only the technical'
              + ' challenges facing us, but also the ethical and educational ones.”'
          }
        }
      }
    }

    return {
      team: texts.headline[this.textLang],
      personProfiles: [
        {
          id: 1,
          avatar: require('../assets/michal.jpg'),
          name: 'Michal Krajňanský',
          title: texts.personProfiles[1].title[this.textLang],
          bio: texts.personProfiles[1].bio[this.textLang],
          link: 'https://www.linkedin.com/in/xkrajnan'
        },
        {
          id: 2,
          avatar: require('../assets/vojta.jpg'),
          name: 'Vojtěch Krajňanský',
          title: texts.personProfiles[2].title[this.textLang],
          bio: texts.personProfiles[2].bio[this.textLang],
          link: 'https://www.linkedin.com/in/vojt%C4%9Bch-kraj%C5%88ansk%C3%BD-710a82a4/'
        },
        {
          id: 3,
          avatar: require('../assets/jirka.jpg'),
          name: 'Jiří Jelínek',
          title: texts.personProfiles[3].title[this.textLang],
          bio: texts.personProfiles[3].bio[this.textLang],
          link: 'https://www.linkedin.com/in/ji%C5%99%C3%AD-jel%C3%ADnek-611270189/'
        }
      ]
    };
  }
};
</script>

<style>
.person-info {
  padding-right: 2em;
  min-width: 6em;
}

.divider {
  background-image: linear-gradient(.25turn, #7202e266, rgb(250, 250, 250));
  background-position: 0;
  min-height: .2em;
  border-radius: 0;
  width: 33%;
}
</style>